<template lang="html">
  <svg
    width="28"
    height="23"
    viewBox="0 0 28 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M14.0084 19.8497C14.0084 20.343 13.7234 20.7911 13.2774 21.0002C13.105 21.0818 12.9202 21.1212 12.7366 21.1212C12.4459 21.1212 12.1579 21.0213 11.9252 20.8284L4.97384 15.0682H1.27152C0.569402 15.0688 0 14.4991 0 13.797V8.73102C0 8.0286 0.569402 7.4592 1.27152 7.4592H4.97414L11.9255 1.69897C12.3053 1.38417 12.832 1.31706 13.2777 1.52773C13.7234 1.73689 14.0087 2.18531 14.0087 2.67827L14.0084 19.8497ZM18.895 17.8628C18.864 17.8649 18.8342 17.8661 18.8035 17.8661C18.4676 17.8661 18.1438 17.7334 17.9046 17.4939L17.7345 17.3232C17.2885 16.8781 17.2361 16.173 17.6117 15.6668C18.5639 14.3829 19.0665 12.861 19.0665 11.2644C19.0665 9.5472 18.4959 7.93651 17.4161 6.6063C17.005 6.1007 17.0429 5.36638 17.5037 4.90592L17.6734 4.73588C17.9274 4.48188 18.2678 4.34344 18.6362 4.36541C18.9949 4.38347 19.3299 4.5523 19.5574 4.83038C21.0552 6.66318 21.8464 8.88842 21.8464 11.2647C21.8464 13.4779 21.1467 15.5846 19.8225 17.356C19.6013 17.6512 19.2631 17.8366 18.895 17.8628ZM24.152 21.7924C23.9221 22.0641 23.5895 22.2272 23.2335 22.2423C23.2161 22.2429 23.1983 22.2435 23.1802 22.2435C22.8435 22.2435 22.5203 22.1105 22.281 21.8712L22.114 21.7042C21.6472 21.2377 21.6156 20.4916 22.0399 19.9866C24.0906 17.5477 25.2204 14.4503 25.2204 11.2644C25.2204 7.95065 24.0109 4.76056 21.8154 2.28192C21.3703 1.77872 21.3929 1.01701 21.8666 0.541508L22.0333 0.37448C22.2807 0.125893 22.6021 -0.0110399 22.9708 0.000697256C23.3208 0.0106287 23.6518 0.165318 23.8845 0.427147C26.5383 3.415 28 7.26418 28 11.2644C28.0006 15.1133 26.634 18.8523 24.152 21.7924Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="28" height="22.24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "MakeCall",
};
</script>
